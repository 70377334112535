import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import { doc, setDoc } from 'firebase/firestore';
import { db } from './firebase';

function EncuestaMejoraTiempo() {
  const [empresa, setEmpresa] = useState('');
  const [sector, setSector] = useState('');
  const [empleados, setEmpleados] = useState('');
  const [sistemaAnterior, setSistemaAnterior] = useState('');
  const [tiempoAnterior, setTiempoAnterior] = useState('');
  const [dificultades, setDificultades] = useState([]);
  const [tiempoResolucionAnterior, setTiempoResolucionAnterior] = useState('');
  const [tiempoActual, setTiempoActual] = useState('');
  const [mejoraRapidez, setMejoraRapidez] = useState('');
  const [mejoras, setMejoras] = useState([]);
  const [tiempoResolucionActual, setTiempoResolucionActual] = useState('');
  const [recomendacion, setRecomendacion] = useState('');
  const [calificacion, setCalificacion] = useState(''); 
  const [comentarios, setComentarios] = useState('');
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = (index) => {
    if (validateCurrentTab()) {
      setActiveTabIndex(index);
    } else {
      toast.error('Por favor, complete todas las preguntas antes de continuar.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const validateCurrentTab = () => {
    switch (activeTabIndex) {
      case 0: // Validación de la sección 1: Información General
        return empresa && sector && empleados;
      case 1: // Validación de la sección 2: Situación Antes de Implementar Ninja-POS
        return sistemaAnterior && tiempoAnterior && dificultades.length > 0 && tiempoResolucionAnterior;
      case 2: // Validación de la sección 3: Situación Después de Implementar Ninja-POS
        return tiempoActual && mejoraRapidez && mejoras.length > 0 && tiempoResolucionActual;
      case 3: // Validación de la sección 4: Evaluación General
        return recomendacion && calificacion;
      default:
        return true;
    }
  };

  const handleCheckboxChange = (e, setState, state) => {
    const { value, checked } = e.target;
    if (checked) {
      setState([...state, value]);
    } else {
      setState(state.filter(item => item !== value));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'empresa':
        setEmpresa(value);
        break;
      case 'sector':
        setSector(value);
        break;
      case 'empleados':
        setEmpleados(value);
        break;
      case 'sistemaAnterior':
        setSistemaAnterior(value);
        break;
      case 'tiempoAnterior':
        setTiempoAnterior(value);
        break;
      case 'tiempoResolucionAnterior':
        setTiempoResolucionAnterior(value);
        break;
      case 'tiempoActual':
        setTiempoActual(value);
        break;
      case 'mejoraRapidez':
        setMejoraRapidez(value);
        break;
      case 'tiempoResolucionActual':
        setTiempoResolucionActual(value);
        break;
      case 'recomendacion':
        setRecomendacion(value);
        break;
      case 'calificacion':
        setCalificacion(value);
        break;
      case 'comentarios':
        setComentarios(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateCurrentTab()) {
      toast.error('Por favor, complete todas las preguntas antes de enviar.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    const encuesta = {
      "Información General": {
        "Nombre de la Empresa": empresa,
        "Sector de la Empresa": sector,
        "Número de Empleados": empleados
      },
      "Situación Antes de Implementar Ninja-POS": {
        "Sistema Anterior": sistemaAnterior,
        "Tiempo de Proceso Anterior": tiempoAnterior,
        "Dificultades": dificultades,
        "Tiempo de Resolución Anterior": tiempoResolucionAnterior
      },
      "Situación Después de Implementar Ninja-POS": {
        "Tiempo de Proceso Actual": tiempoActual,
        "Mejora en la Rapidez": mejoraRapidez,
        "Mejoras Notadas": mejoras,
        "Tiempo de Resolución Actual": tiempoResolucionActual
      },
      "Evaluación General": {
        "Recomendación": recomendacion,
        "Calificación de la Mejora": calificacion,
        "Comentarios Adicionales": comentarios
      }
    };

    toast.success('Encuesta enviada correctamente.', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    await setDoc(doc(db, 'encuestas', empresa), encuesta);
    console.log(JSON.stringify(encuesta, null, 2)); // Imprime el JSON estructurado en la consola
  };

  return (
    <>
      <ToastContainer />
      <form className="max-w-xl mx-auto mt-8 space-y-6" onSubmit={handleSubmit}>
        <Tabs selectedIndex={activeTabIndex} onSelect={handleTabChange}>
          <TabList>
            <Tab>Empresa</Tab>
            <Tab>Antes</Tab>
            <Tab>Después</Tab>
            <Tab>Evaluación</Tab>
          </TabList>

          {/* Sección 1: Información General */}
          <TabPanel>
            <div className="bg-white shadow rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-4">Información General</h2>
              <div className="space-y-4">
                <div>
                  <label htmlFor="empresa" className="block text-sm font-medium text-gray-700">
                    **Nombre de la Empresa**
                  </label>
                  <input
                    id="empresa"
                    name="empresa"
                    type="text"
                    value={empresa}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="sector" className="block text-sm font-medium text-gray-700">
                    **Sector de la Empresa**
                  </label>
                  <div className="space-y-2">
                    {['Hotel', 'Restaurante', 'Botica', 'Ferretería', 'Minimarket', 'Comida rápida', 'Lubricentro','Retail','Otro'].map((option, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          id={`sector-${option}`}
                          name="sector"
                          type="radio"
                          value={option}
                          checked={sector === option}
                          onChange={handleChange}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={`sector-${option}`} className="ml-3 block text-sm text-gray-700">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label htmlFor="empleados" className="block text-sm font-medium text-gray-700">
                    **Número de Trabajadores**
                  </label>
                  <input
                    id="empleados"
                    name="empleados"
                    type="number"
                    value={empleados}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <button
                type="button"
                onClick={() => handleTabChange(1)}
                className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Siguiente
              </button>
            </div>
          </TabPanel>

          {/* Sección 2: Situación Antes de Implementar Ninja-POS */}
          <TabPanel>
            <div className="bg-white shadow rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-4">Situación Antes de Implementar Ninja-POS</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    **¿Qué sistema utilizaba su empresa antes de implementar Ninja-POS?**
                  </label>
                  <div className="space-y-2">
                    {['Sistema propio', 'Sistema de escritorio', 'Sin sistema (procesos manuales)', 'Otro'].map((option, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          id={`sistemaAnterior-${option}`}
                          name="sistemaAnterior"
                          type="radio"
                          value={option}
                          checked={sistemaAnterior === option}
                          onChange={handleChange}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={`sistemaAnterior-${option}`} className="ml-3 block text-sm text-gray-700">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    **¿Cuánto tiempo tomaba, en promedio, el proceso de venta desde que el cliente solicitaba el producto/servicio hasta que se completaba la transacción?**
                  </label>
                  <div className="space-y-2">
                    {['1 minuto', 'Menos de 3 minutos', 'Menos de 5 minutos', 'Entre 5 y 10 minutos', 'Entre 10 y 15 minutos', 'Más de 15 minutos'].map((option, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          id={`tiempoAnterior-${option}`}
                          name="tiempoAnterior"
                          type="radio"
                          value={option}
                          checked={tiempoAnterior === option}
                          onChange={handleChange}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={`tiempoAnterior-${option}`} className="ml-3 block text-sm text-gray-700">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    **¿Cuáles eran las principales dificultades que enfrentaba en el proceso de venta? (Seleccione todas las que apliquen)**
                  </label>
                  <div className="space-y-2">
                    {['Sistema lento', 'Falta de soporte técnico', 'Problemas de compatibilidad con otros sistemas operativos', 'Necesidad de realizar procesos manuales'].map((option, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          id={`dificultades-${option}`}
                          name="dificultades"
                          type="checkbox"
                          value={option}
                          onChange={(e) => handleCheckboxChange(e, setDificultades, dificultades)}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={`dificultades-${option}`} className="ml-3 block text-sm text-gray-700">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    **¿Cuánto tiempo tomaba en promedio resolver un problema técnico relacionado con el sistema de ventas?**
                  </label>
                  <div className="space-y-2">
                    {['Menos de 1 hora', 'Entre 1 y 4 horas', 'Más de 4 horas', 'No se resolvía'].map((option, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          id={`tiempoResolucionAnterior-${option}`}
                          name="tiempoResolucionAnterior"
                          type="radio"
                          value={option}
                          checked={tiempoResolucionAnterior === option}
                          onChange={handleChange}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={`tiempoResolucionAnterior-${option}`} className="ml-3 block text-sm text-gray-700">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={() => handleTabChange(2)}
                className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Siguiente
              </button>
            </div>
          </TabPanel>

          {/* Sección 3: Situación Después de Implementar Ninja-POS */}
          <TabPanel>
            <div className="bg-white shadow rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-4">Situación Después de Implementar Ninja-POS</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    **¿Cuánto tiempo toma ahora, en promedio, el proceso de venta con Ninja-POS?**
                  </label>
                  <div className="space-y-2">
                    {['1 minuto', '2 minutos', 'Menos de 5 minutos', 'Entre 5 y 10 minutos', 'Entre 10 y 15 minutos', 'Más de 15 minutos'].map((option, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          id={`tiempoActual-${option}`}
                          name="tiempoActual"
                          type="radio"
                          value={option}
                          checked={tiempoActual === option}
                          onChange={handleChange}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={`tiempoActual-${option}`} className="ml-3 block text-sm text-gray-700">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    **¿Ha notado una mejora en la rapidez del proceso de venta desde la implementación de Ninja-POS?**
                  </label>
                  <div className="space-y-2">
                    {['Sí, significativamente más rápido', 'Sí, un poco más rápido', 'No ha cambiado', 'No, es más lento ahora'].map((option, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          id={`mejoraRapidez-${option}`}
                          name="mejoraRapidez"
                          type="radio"
                          value={option}
                          checked={mejoraRapidez === option}
                          onChange={handleChange}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={`mejoraRapidez-${option}`} className="ml-3 block text-sm text-gray-700">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    **¿Qué mejoras ha notado con Ninja-POS en comparación con su sistema anterior? (Seleccione todas las que apliquen)**
                  </label>
                  <div className="space-y-2">
                    {['Proceso más rápido y automatizado', 'Compatibilidad con múltiples sistemas operativos', 'Soporte técnico más eficiente', 'Menos problemas técnicos'].map((option, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          id={`mejoras-${option}`}
                          name="mejoras"
                          type="checkbox"
                          value={option}
                          onChange={(e) => handleCheckboxChange(e, setMejoras, mejoras)}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={`mejoras-${option}`} className="ml-3 block text-sm text-gray-700">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    **¿Cuánto tiempo toma ahora en promedio resolver un problema técnico relacionado con Ninja-POS?**
                  </label>
                  <div className="space-y-2">
                    {['Menos de 1 hora', 'Entre 1 y 4 horas', 'Más de 4 horas', 'No se ha presentado ningún problema'].map((option, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          id={`tiempoResolucionActual-${option}`}
                          name="tiempoResolucionActual"
                          type="radio"
                          value={option}
                          checked={tiempoResolucionActual === option}
                          onChange={handleChange}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={`tiempoResolucionActual-${option}`} className="ml-3 block text-sm text-gray-700">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={() => handleTabChange(3)}
                className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Siguiente
              </button>
            </div>
          </TabPanel>

          {/* Sección 4: Evaluación General */}
          <TabPanel>
            <div className="bg-white shadow rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-4">Evaluación General</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    **¿Recomendaría Ninja-POS a otras empresas en su sector?**
                  </label>
                  <div className="space-y-2">
                    {['Sí', 'No', 'Tal vez'].map((option, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          id={`recomendacion-${option}`}
                          name="recomendacion"
                          type="radio"
                          value={option}
                          checked={recomendacion === option}
                          onChange={handleChange}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={`recomendacion-${option}`} className="ml-3 block text-sm text-gray-700">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    **En una escala del 1 al 5, ¿cómo calificaría la mejora en el proceso de venta después de implementar Ninja-POS?**
                  </label>
                  <div className="space-y-2">
                    {['1', '2', '3', '4', '5'].map((option) => (
                      <div key={option} className="flex items-center">
                        <input
                          id={`calificacion-${option}`}
                          name="calificacion"
                          type="radio"
                          value={option}
                          checked={calificacion === option}  // Corregido: Uso de ===
                          onChange={handleChange}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                        <label htmlFor={`calificacion-${option}`} className="ml-3 block text-sm text-gray-700">
                          {option} {option === '1' ? '(Ninguna mejora)' : option === '5' ? '(Gran mejora)' : ''}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <label htmlFor="comentarios" className="block text-sm font-medium text-gray-700">
                    **Comentarios adicionales**
                  </label>
                  <textarea
                    id="comentarios"
                    name="comentarios"
                    value={comentarios}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Enviar Encuesta
              </button>
            </div>
          </TabPanel>
        </Tabs>
      </form>
    </>
  );
}

export default EncuestaMejoraTiempo;
