import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDZ0m6v3UFdZJWjPvxxXRPtkZhumyMC4t8",
    authDomain: "apiprueba-df335.firebaseapp.com",
    projectId: "apiprueba-df335",
    storageBucket: "apiprueba-df335.appspot.com",
    messagingSenderId: "663913416666",
    appId: "1:663913416666:web:c4b82bf6832a6d3715a2e3",
    measurementId: "G-NN1XDBP1G1"
};
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
